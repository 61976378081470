site.dropdownMenu = {
	init: function () {
		var $body = $('body');
		if ($body.width()+15 >= 992) {
			$('#mainmenu .level1.children > ul').each(function () {
				var $this = $(this);
				$this.css('max-height', $this[0].scrollHeight+80);
				var left =  ($this.parent().outerWidth()/2) - ($this.outerWidth()/2);
				$this.css('left', left);
			});
		};
		$('.level1.children a', '#mainmenu').on('click', function (e) {
			var $this = $(this);
			if ($body.width()+15 >= 992) {
				if ($(this).parent().hasClass('level1')) {
					e.preventDefault();
					e.stopPropagation();
					$this.parent().siblings().removeClass('open');
					$body.removeClass('search-open');

					if (!$this.parent().hasClass('open')) {
						$this.parent().addClass('open');
						$body.toggleClass('dropdown-open');
						$('body.dropdown-open').on('click', function (e) {
							$body.removeClass('dropdown-open');
							$('.level1.open','#mainmenu').removeClass('open');
						});
					}
					else {
						$body.removeClass('dropdown-open');
						$('.level1.open','#mainmenu').removeClass('open');
					}
				}
			}
		});
		site.dropdownMenu.sidebarMenu();
	},
	sidebarMenu: function() {
		var $sidebarMenu = $('#sidebar');
		$('.children > a', $sidebarMenu).after('<a href="#" class="expand"></a>');
		$('.selectedLevel2', $sidebarMenu).addClass('down');
		$('.selectedLevel3', $sidebarMenu).addClass('down');
		$('.selectedLevel4', $sidebarMenu).addClass('down');
		$('.expand', $sidebarMenu).bind('click', function(e) {
			e.preventDefault();
			$(this).closest('li').toggleClass('down');
		});
	}
};
site.inits.push('site.dropdownMenu.init()');
