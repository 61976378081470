site.searchBox = {
	init: function() {
		var $searchBox = $('#searchBox');
		if ($searchBox.length > 0) {
			if ($searchBox.hasClass('toggle')) {
				site.searchBox.toggle();
			}
		}
	},

	toggle: function() {
		var $body = $('body'),
			$s = $('#searchBox');

		$s.on('click touchstart', function (e) {
			e.stopPropagation();
		});

		$('#searchToggle').on('click touchstart', function (e) {
			e.stopPropagation();
			e.preventDefault();

			$body.toggleClass('search-open');

			$('body.search-open').on('click touchstart', function (e) {
				site.searchBox.close(true);
			});

			if ($body.hasClass('search-open')) {
				$s.find('#q').focus();
				$('i', '#searchToggle').removeClass('fa-search').addClass('fa-close');
			}
			else {
				site.searchBox.close(true);
			}
		});

		site.searchBox.width();
		$(window).resize(function() {
			site.searchBox.width();
		});
	},

	close : function(closing) {
		closing = closing || false;
		var $body = $('body');

		if (!closing && !$body.hasClass('search-open')) return;

		$body.removeClass('search-open');
		$('i', '#searchToggle').removeClass('fa-close').addClass('fa-search');

		if (closing) {
			$body.addClass('search-closing')
			setTimeout(function() {
				$('body').removeClass('search-closing');
			},400);
		}
	},

	width: function() {
		if ($('body').width() + 15 < 992) return false;

		var $searchBox = $('#searchBox'),
			width = 5;
		$('.level1', '#mainmenu').each(function() {
			if ($(this).is(":visible")) {
				width += $(this).outerWidth(true);
			}
		});
		$('#q', $searchBox).css('min-width', width);
		$('form', $searchBox).css('width', width + 5);
	}
};

site.inits.push('site.searchBox.init()');
