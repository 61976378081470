site.hero = {
	init:function() {
		var $owl = $('#owl-hero');
		if (!$owl) return false;

		if ($('.item', $owl).length > 1) {
			site.owl.init('#owl-hero .items', {
				loop: true,
				dots: true,
				nav: false,
				items: 1,
				navText: ["", ""]
			});
		}
	}
};

site.inits.push('site.hero.init()');
