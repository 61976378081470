var site = site || {};
site.inits = [];

site.window = {
	scrollPosition: 0
};

site.device = {
	// Check if device is a touch device
	isTouch: function() {
		try {
			document.createEvent("TouchEvent");
			return true;
		} catch (e) {
			return false;
		}
	}
};


// A few layout methods
site.layout = {
	// Wrap elements into a div.row
	rowWrap: function(selectorBegin, selectorEnd, extraClasses) {
		if (selectorEnd == undefined) {
			selectorEnd = selectorBegin;
		}

		if (extraClasses == undefined) {
			extraClasses = '';
		}

		$(selectorBegin).nextUntil(selectorEnd).andSelf().add(selectorEnd)
			.wrapAll("<div class='row " +extraClasses+ "'></div>");
	}
}


site.owl = {
	// Shorthand to init owlcarousel
	init: function(id, options) {
		var $owl = $(id);
		if ($owl.length > 0) {
			$owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
			$owl.owlCarousel(options);
		}
	}
};

site.accessibility = {
	init:function() {
		$(document).keydown(function (e) {
			if (e.which === 9) {
				$('body:not(.admin)').addClass('tabbing');
			}
		});
		$(document).mousedown(function (e) {
			$('body.tabbing').removeClass('tabbing');
		});
	}
};


site.frontpage = {
	init: function() {
		if ($('#custom-banner .item').length > 1) {
			site.owl.init('#custom-banner .items', {
				loop: true,
				dots: true,
				nav: false,
				items: 1,
				navText: ["", ""],
				stopOnHover: true,
				autoplay: false,
				animateOut: 'fadeOut',
				animateIn: 'fadeIn',
			});
		}
	}
};

// Run all the inits \o/
$(function() {
	$.each(site.inits, function(i, value) {
		var fun = new Function(value);
		fun();
	});

	site.accessibility.init();

	var $body = $('body');
	if ($body.is('.frontpage')) {
		site.frontpage.init();
	}

	//Responsive youtube and vimeo iframe
	$('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]', '#main').each(function() {
		$(this).attr('allowfullscreen','').wrap('<div class="video" style="max-width:'+$(this).width()+'px"><div class="embed-responsive embed-responsive-16by9"></div></div>');
	});

	// Responsive tables
	if (!$body.is('.admin')) {
		$('table', '#main').wrap('<div class="table-responsive"></div>');
	}

	// Reorder language menu based on selected
	// Move is to the top
	//$li = $('.selected', '#divisions');
	//$ul = $('.menu', '#divisions');
	//$ul.prepend($li);
});
